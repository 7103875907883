import * as React from "react";
import { graphql } from "gatsby";
import {withLayout} from "../components/Layout";

const PhotoPage = (props: any) => {
  const photos = props.data.photos.nodes;

  return (
    <div className="pa4">
      <h1>Fotos</h1>
      {
        photos.map((photo, i) => (
          <div className="fl">
            <a className="pa2" href={photo.picture.scaled.hd.src}>
              <img
                srcset={photo.picture.scaled.thumbnail.srcSet}
                src={photo.picture.scaled.thumbnail.src}
                alt={photo.Description}
                title={photo.Description}
              />
            </a>
          </div>
        ))
      }
      <p className="cf" />
    </div>
  );
};

export default withLayout(PhotoPage);

export const pageQuery = graphql`
query AllPhotosQuery {
  photos: allStrapiPhoto {
    nodes {
      Description
      picture {
        absolutePath
        scaled: childImageSharp {
          thumbnail: resolutions(height: 300, toFormat: JPG, fit: CONTAIN) {
            src
            srcSet
          }
          hd: resolutions(height: 1200) {
            src
          }
        }
      }
    }
  }
}
`;
